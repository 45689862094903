body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  /* padding-top: 3rem; */
  padding-bottom: 3rem;
  color: #5a5a5a;
  /* background-color: #FFFEF2; */
}
.ul{ 
  list-style-type: none;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
  background-position: center;
  background-size: cover;
}
/* .carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
} */


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
  background-position: center;
  background-size: cover;
}

.marketing h2 {
  margin-top: 15px;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
.textcolor{
  color: #99999999;
}



a{
    color: blue;
}

nav{
    background-color: #F0F3F4;
    border: black;
}

.navbar-collapse ul li a:hover{
    color: rgba(255, 99, 71, 0.8);
    transition: .3s ease;
}

.navbar-brand:hover{
    color: rgba(255, 99, 71, 0.8);
    transition: .3s ease;
}

.nav-link:hover{
    color: rgba(255, 99, 71, 0.8);
    transition: .3s ease;
}

.navbar-toggle{
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
}
.span{
    background-color: #121212;

}

.nav-link{
    border: none;
}

.login{
    text-decoration: none;
}

.btn:hover{
    color: rgba(255, 99, 71, 0.8);
    transition: .3s ease;
}



.white {
    background-color: #FFF;
}

section {
    width: 100%;
    padding: 2rem;
    background-color: #FFF;
    box-shadow: -3px 5px 8px 4px rgba(0, 0, 0, 0.1);
  }
.center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.center button{
    height:50px;
    width:200px;
}
  

xmp:before {
    content: counter(line);
    counter-increment: line;
  }
  
  pre {
    white-space: nowrap;
    counter-reset: line;
    min-height: 350px;
    background: #ececec;
    padding: 15px;
    overflow-y: scroll
  }
  code *{
    color: black;
  }
